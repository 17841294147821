import Header from './../components/Header';
import Interpreter from '../components/Interpreter';
import { Container } from '../components/styles/Container.styled';

import Info from '../headers'
import Abt from '../aboutPage';

function About() {
    return (
        <>
        <Header data={Info.about}/>
        <Container>
            <Interpreter data={Abt} />
        </Container>
        </>
    )
}

export default About;