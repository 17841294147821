import { StyledHeader, Background, Title, Body, Image, Card} from "./styles/Header.styled"
import { Container } from "./styles/Container.styled"
import { Flex } from "./styles/Flex.styled"
//import Nav from "./Nav"

export default function Header({data: {image, title, body}}) {
    return (
        <StyledHeader>
            <Background image={image}>
                <Card>
                    <Title>
                        {title}
                    </Title>
                    <Body>
                        {body}
                    </Body>
                </Card>
            </Background>
        </StyledHeader>
    )
}