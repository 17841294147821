
import SocialIcons from "./SocialIcons"
import { Container } from "./styles/Container.styled"
import { Flex } from "./styles/Flex.styled"
import { StyledFooter } from "./styles/Footer.styled"

export default function Footer(){
    return (
        <StyledFooter>
                <div>
                    <h2>Contact</h2>
                    <p>&emsp;+1(281)-788-7746</p>
                    <a>&emsp;info@jacintotsi.com</a>
                </div>
                <div>
                    <h2>About Us</h2>
                    <p>&emsp;Jacinto Technical Services is a consulting agency dedicated<br></br>&emsp;
                        to serving all of your pipeline and engineering needs
                    </p>
                </div>
        </StyledFooter>
    )
}