import styled from "styled-components";

export const Container = styled.div`
    width: 70%;
    //max-width: 100%;
    padding: 10px 10px;
    min-height: 100%;
    margin: 0 15%;
    display: inline-block;
    @media screen and (max-width: 768px) {
        margin: 0 0;
        width: 100%;
    }
`

export const Background = styled.div`
    width: 100%;
    min-height: 100%;
    position: relative;
    padding: 100px 100px;
    background-color: #333;
    //background-image: url("background.png");
    //background-repeat: repeat;
    box-shadow: 0 15px 22px rgba(0, 0, 0, 1);
`