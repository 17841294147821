const abt = {
  body: {
    1: {
      component: 'card',
      data: {
        title: 'Who We Are',
        body: 'We are a team of experienced engineers and technical personnel\
 who have worked for both well-established pipeline industry leaders as well as start-up\
 development companies.'
      }
    },
    2: {
      component: 'card',
      data: {
        title: 'What We Do',
        body: 'JTSI seeks to assist our client base with technical services for development\
 and execution of pipeline projects.'
      }
    },
    3: {
      component: 'card',
      data: {
        title: 'Why We Do What We Do',
        body: '\tJTSI understands that there is significant capital investment required for building\
 pipelines and related facilities. JTSI\’s team utilizes our extensive experience to help our\
 clients convert that capital investment into working assets.\
 We have seen many of our clients go on to be acquired with significant returns based on the\
 assets we have helped them build.  We have seen others grow their revenues through increasing\
 throughput on the systems we’ve helped them build. \
The bottom line is our clients\’ success is our success. \
We enjoy making your projects profitable.'
      }
    },
  }
}

export default abt;