import Header from './../components/Header';
import {Sub} from '../components/Interpreter';
import { Container } from '../components/styles/Container.styled';

import Info from '../headers'
import {Directory} from '../projectPage';

// GENERATE SERVICES PAGE
function Projects() {
    return (
        <>
        <Header data={Info.projects}/>
        <Container>
            <Sub data={Directory}/>
        </Container>
        </>
    )
}
export default Projects;