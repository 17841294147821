import Header from './../components/Header';
import Card from '../components/Card';
import Grid from '../components/Grid';
import SideNav, { SubNav } from '../components/SubNav';
import { Routes, Route } from 'react-router-dom';
import { ContentContainer } from './styles/SubNav.styled';
import { useLocation } from "react-router-dom"

function Interpreter({data: {body}}) {
	const componentNames = {
		card: Card, //takes data
		grid: Grid, // takes data
		head: Header, // takes data
	};

	return (
		Object.keys(body).map(key => {
		let Component = componentNames[body[key].component];
		{
			return (<Component data={body[key].data} />)
		}
		})
	)
};

function Temp({page: {body}}) {
	if(body != undefined){
		return (<Interpreter data={body}/>)
	}
	else{
			return(<h2>Error 404: Page Not Found</h2>)
	}
}

export function Sub({data: {links, routes}}) {
	return (
		<>
		<SubNav data={links} />
		<ContentContainer>
			<Routes>
				{Object.keys(routes).map(key => {
				let ph = "/" + routes[key].route;
				return (
					<Route path={ph} element={ <Temp page={routes[key][routes[key].route]} /> } />
				)
				})}
			</Routes>
		</ContentContainer>
		</>
	)

}

export default Interpreter;