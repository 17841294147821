const info = {
    home: 
    {
        image: 'homePage.jpg',
        title: 'Jacinto Technical Services Inc.',
        body: 'Making your projects profitable'
    }, 
    services:
    {
        image: 'service.jpg',
        title: 'What we do',
        body: 'Here you can find info on what services we provide'
    },
    projects:
    {
        image: 'projects.jpg',
        title: 'What we\'ve done',
        body: 'Take a look at our previous projects'
    },
    about:
    {
        image: 'about.jpg',
        title: 'Who We Are',
        body: 'Why we do what we do and what got us started'
    },
    contact:
    {
        image: 'contact.jpg',
        title: 'Anything you\'d like to get in touch about?',
        body: 'Feel free to fill out the form below to get in contact'
    }
}


export default info;