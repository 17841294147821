import { render } from "@testing-library/react";
import { publicIp, publicIpv4 } from "public-ip"
import { FormContainer, 
    Form, 
    FormBlock, 
    FormLine, 
    FormSection, 
    TextSection, 
    Input, 
    Text, 
    Label, 
    Submit, 
    SubmitContainer,
    Req} from "./styles/Form.styled";
import Captcha from "./Captcha";
import { useState, useRef, useEffect } from "react";
import {time, input} from "./Captcha";
  
function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
 }

export default function ContactForm() {
    const [ip, setIP] = useState("");
    const nameForm = useRef(null);

    async function getClientIp() {
        var dat = null;
        dat = await publicIpv4({
            fallbackUrls: [ "https://ifconfig.co/ip" ]
        });
        setIP(dat);
    }

    getClientIp();
    function submitForm() {
        const form = nameForm.current;
        form['captchaInput'].value = `${input}`;
        form['captchaTime'].value = time; 
        form['data'].value = ip;
    }

    return (
            <Form action="/mail" method="POST" id="mailform" ref={nameForm} >
                <FormLine>
                    <FormSection>
                        <Label>First Name<Req>*</Req></Label>
                        <Input type="text" required name="fname" placeholder=""/>
                    </FormSection>
                    <FormSection>
                        <Label>Last Name<Req>*</Req></Label>
                        <Input type="text" required name="lname" placeholder=""/>
                    </FormSection>
                </FormLine>
                <FormLine>
                    <FormSection>
                        <Label>Company<Req>*</Req></Label>
                        <Input type="text" required name="company" placeholder=""/>
                    </FormSection>
                </FormLine>
                <FormLine>
                    <FormSection>
                        <Label>Email<Req>*</Req></Label>
                        <Input type="email" required name="email" placeholder=""/>
                    </FormSection>
                    <FormSection>
                        <Label>Phone</Label>
                        <Input type="text" name="phone" placeholder=""/>
                    </FormSection>
                </FormLine>
                <FormLine>
                    <FormSection>
                        <Label>Subject<Req>*</Req></Label>
                        <Input type="text" name="subject" required placeholder="" autocomplete="off"/>
                    </FormSection>
                </FormLine>
                <FormLine>
                    <TextSection>
                        <Label>Message<Req>*</Req></Label>
                        <Text type="text" name="message" required rows="7" placeholder="How can we help?" />
                    </TextSection>
                </FormLine>
                <FormLine>
                    <SubmitContainer>
                        <Captcha />
                        <input type='hidden' required name='captchaInput' />
                        <input type='hidden' required name='captchaTime' />
                        <input type='hidden' required name='data' />
                    </SubmitContainer>
                </FormLine>
                <FormLine>
                    <SubmitContainer>
                        <Submit type="submit" onClick={submitForm} value="Submit"/>
                    </SubmitContainer>
                </FormLine>
            </Form>
    )
}