const homecontent = {
  body: {
    1: {
      component: 'card',
      data: {
        title: 'Who We Are',
        body: `JTSI is a licensed professional engineering team offering consulting services to the pipeline and energy industries.\
 We offer over 75 years of experience in the pipeline and energy industries.\
 We specialize in all areas of engineering and project management related to pipelines and associated facilities.\
 Additionally, we offer our clients complete project services from economic modeling through operational support in products ranging from crude oil and natural gas to refined products to petrochemicals and purity gases.\
 Our project portfolio includes line sizes from 2” to 42” with lengths from ½ mile to over 100 miles. We’ve managed projects ranging from $50,000 to $175,000,000.\
 In addition to pipeline engineering and project management services, we provide technical services to support pipeline operations.`
      }
    },
    2: {
      component: 'grid',
      data: {
        title: 'Services',
        tabs: 
        {
          1:
          {
            title: 'Project Consulting and Development',
            desc: '',
            link: '/services/consultingdevelopment'
          },
          2:
          {
            title: 'Project Management',
            desc: '',
            link: '/services/projectmanagement'
          },
          3:
          {
            title: 'Design and Engineering',
            desc: '',
            link: '/services/engineering'
          },
        },
      }
    },
    3: {
      component: 'grid',
      data: {
        title: 'Projects',
        tabs: 
        {
          1:
          {
            title: 'Purity Ethylene Pipeline System',
            desc: '',
            link: '/projects/ethylene'
          },
          2:
          {
            title: 'Purity Ethane Pipeline System',
            desc: '',
            link: '/projects/ethane'
          },
          3:
          {
            title: 'Ammonia Pipeline System',
            desc: '',
            link: '/projects/ammonia'
          },
          4:
          {
            title: 'Helium Pipeline',
            desc: '',
            link: '/projects/helium'
          },
          5:
          {
            title: 'Liquified Natural Gas Loading Pipelines',
            desc: '',
            link: '/projects/lngl'
          },
        },
      }
    },
    4: {
      component: 'head',
      data: {
        image: 'about.jpg',
        title: 'About Us',
        body: "Read more about our mission on our About Page"
      },
    },
    
  }
}


export default homecontent;