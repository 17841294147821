import styled from "styled-components"


export const Form = styled.form`
    max-width: 500px;
    margin: auto;
    width: 60%;
    text-align: center;
    padding: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    @media screen and (max-width: 768px){
        width: 90%;
    }
`;

export const FormLine = styled.div`
    width: 100%;
    margin: 10px 0px;
    display: flex; 
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 768px){
        flex-direction: column;
        justify-content: center;
        margin: auto;
    }
`;

export const FormSection = styled.div`
    //margin: auto;
    width: 225px;
    text-align: center;
    @media screen and (max-width: 768px){
        width: 100%;
        margin-bottom: 20px;
    }

`;

export const TextSection = styled.div`
    width: 100%;
    text-align: center;
`;

export const Label = styled.div`
    float: left;
    
    @media screen and (max-width: 768px){
        width: 100%;
        float: left;
        text-align: left;
    }
`;

export const Input = styled.input`
    box-sizing: border-box;
    border: 3px solid #777;
    margin: 10px 0px;
    padding: 10px 5px;
    font-size: 16px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    outline: none;
    &:focus {
    border: 3px solid #111;
    }
    @media screen and (max-width: 768px){
        width: 100%;
        margin: auto;
    }
`;

export const Text = styled.textarea`
    font-family: Arial, sans-serif;
    font-size: 16px;
    box-sizing: border-box;
    border: 3px solid #777;
    margin: 10px 0px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    outline: none;
    width: 97%;
    &:focus {
    border: 3px solid #333;
    }
    @media screen and (max-width: 768px){
        width: 100%;
        margin: auto;
    }
`;

export const SubmitContainer = styled.div`
    text-align: center;
    margin: auto;
    width: 60%;

    @media screen and (max-width: 768px){
        width: 100%;
    }
`;

export const Submit = styled.input`
    background-color: #fff;
    margin: auto;
    padding: 10px 14px;
    border: 3px solid #777;
    width:100%;
    font-size: 1.15em;
    &:hover {
        background-color: #555;
        color:#fff;
        border: 3px solid #333;
    }
`;

export const Req = styled.span`
    color: #a00;
`