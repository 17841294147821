import { FaBars } from 'react-icons/fa';
import { NavLink as Link } from 'react-router-dom';
import styled from 'styled-components';


export const SideLink = styled(Link)`
  color: #fff;
  background: #003Ed8;
  //width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  padding: 20px 20px;
  font-family: 'Futura', sans-serif;
  font-size: 18px;
  font-weight: 400;
  //margin-bottom: 5px;
  //padding: 20px 20px;
  justify-content: left;
  z-index: 0;
  border-left: 10px solid #97C1E6;
  cursor: pointer;
  &.active {
    color: #0fd;

    &:hover{
      color: #fff;
    }
  }
  &:hover {
    //color: #97C1E6;
    background-color: #003E78;
  }
`;

export const SideBar = styled.div`
  margin-top: 20px;
  float: left;
  //display: block;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  //margin-right: 0px;
  width: 25%;
  left: 0;
  z-index: 0;
  border-right: 1px solid #cccccc;
  padding-right: 10px;
  ${SideLink} {
    justify-content: left;
      &:hover {
        //margin-left: 5%;
        //width: 95%;
        margin-left: 7px;
        border-left: 0px solid transparent;// #000;
        border-right: 10px solid #003E78;
        box-shadow: 0px 4px 8px 0px #000,
        -7px 0px 0px 0px #97C1E6;
        z-index: 1;
      }
      //border-bottom: 2px solid #001E40;
    }
  /* Second Nav */
  /* margin-right: 24px; */
  /* Third Nav */
  /* width: 100vw;
  white-space: nowrap; */
  @media screen and (max-width: 768px) {
    display: none;
  }
`;


export const ContentContainer = styled.div`
  float: left;
  width: 75%;
  z-index: 0;

  @media screen and (max-width: 768px) {
    float: none;
    width: 90%;
    margin: auto;
  }
  
`