import styled from "styled-components"

export const StyledFooter = styled.footer`
    background-color: #333;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    min-height: 40px;
    padding-bottom: 25px;
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 12px;
    h2 {
        margin-block-end: 0em;
    }
    p {
        margin-block-start: 0.1em;
        margin-block-end: 0.1em;
    }

    @media screen and (max-width: 768px) {
        flex-direction: column;
        justify-content: center;
        padding-bottom: 20px;
        div {
            width: 90%;
            margin: auto;
        }
    }
`