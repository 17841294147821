const descriptions = {
    consultingdevelopment: {
        body:
        {
        1: {
            component: 'card',
            data: {
                title: 'Project Consulting and Development',
                body: 
`   JTSI offers many services to help our clients evaluate new projects. Pipelines and associated \
facilities can be significant capital expenses, and there are many variables and project risks \
which can affect the cost of these assets. Our clients want to understand those costs and risks \
before fully committing to a project.

    JTSI conducts hydraulics modeling studies to determine the size and capacity of a new asset. We \
also conduct routing studies to identify routes for new pipeline assets, taking into consideration \
factors such as existing geography, infrastructure, constructability, landownership, power \
availability, environmental impacts, etc. JTSI provides estimated project costs for evaluating \
multiple pipeline options to assist our clients in determining the optimum route and size for a \
new pipeline asset.

    In addition, JTSI evaluates front end sizing of pipeline-related facilities, such as pump stations, \
compressor stations, and meter stations. JTSI helps clients with evaluating and determining costs \
for the proper equipment and its installation early in the project cycle.

    If desired, JTSI can use this initial information to further develop a project for client stage \
gate project processes.  JTSI provides total project scope definition, design basis documents, \
and project execution plans. We develop comprehensive project schedules for front-end planning, \
which includes cash flow modeling to assist clients with acquiring financial backing or AFE \
approvals. Another front-end project service that JTSI offers is cost-of-operation studies to help \
clients get an overall sense of project costs early in the project development process.

    Should a client have a project that is in progress, JTSI can be of support with feasibility and \
constructability reviews of ongoing project plans. We perform reviews of asset designs to help \
clients get comfortable with new designs.

    Finally, JTSI provides the following additional consulting services: technical support for \
pipeline-related issues, special studies on pipelines and related facilities, acting as owner’s \
engineer for our clients, and assisting clients with due diligence exercises for acquisition of \
existing assets.
`,
            }
        }
        }
    },
    projectmanagement: {
        body:
        {
        1: {
            component: 'card',
            data: {
                title: 'Pipeline Project Life Cycle',
                body: 
`   Below is a brief summary of the major steps in a typical pipeline project life cycle \
along with a description of services JTSI provides to support our clients in the \
execution of these projects.`,
            }
        },
        2: {
            component: 'card',
            data: {
                title: 'Project Planning and Definition',
                body: 
`   As a pipeline project is moving through the Final Investment Decision (FID) process, \
the project scope details should be finalized in order to transition into a smoothly executable \
project once funding is approved. These details include a well defined route of the pipeline \
based on information gathered in the front-end planning and development stage of the project. \
Based on the proposed route a good hydraulic model should be produced to understand the \
performance expectations of the system and line sizing. Additionally, a design basis should \
be developed to define design expectations for the system and provide a basis for the project \
estimate. Utilizing all of this technical information, a Class 3 estimate can be created for \
both budget authorization, as well as cost control throughout the life of the project. In \
addition to the estimate, a project schedule can be established for tracking the progress \
of work as the project proceeds. If necessary, the schedule and cost estimate can be combined \
to develop a cash flow plan to help clients better plan for the major expenditures over the \
course of the project life cycle.

    JTSI works with clients to finalize a pipeline route based on routing studies taking into \
consideration factors such as existing geography, infrastructure, constructability, land \
ownership, power availability, environmental impacts, etc. JTSI conducts hydraulics modeling \
studies to determine the range of capacities of a new asset for various anticipated operating \
conditions based on the line size selected. JTSI utilizes its years of experience to create \
an estimate with an understanding of current industry conditions. Also, with JTSI’s experience, \
we can build a project schedule to help our clients understand the timing and risk factors \
associated with the anticipated project execution. These tools can then be used by JTSI to \
prepare a cash flow model to understand major expenditures during project execution.
`,
            }
        },
        3: {
            component: 'card',
            data: {
                title: 'Land Acquisition',
                body: 
`   One of the first steps in executing a pipeline project is acquisition of land rights for \
construction and permanent location of the pipeline asset. These rights can come in a variety \
of forms from fee strips to defined easements to blanket easements. These rights are typically \
documented in a legal form which can address anything from temporary construction limitations to \
operations and maintenance activities to indemnification of all parties involved. Additionally, \
the physical location allowed for installation of the pipeline is defined in both legal description \
and plat drawing forms as part of this process. 

    In order to accomplish this phase of the project, typically three resources are contracted by \
the asset owner. These are a land surveyor, right of way company, and legal counsel. It is best \
to utilize a land surveyor who has experience in linear projects, specifically pipeline projects \
and has worked in the geographic area in which the proposed pipeline will be constructed. Similarly, \
identifying and working with an experienced right of way contractor is key to the success of the \
project. Finally, legal counsel is important to navigate the negotiations and ensure that the \
owners rights are completely defined. Owners may elect to utilize their in-house legal counsel \
or bring in external counsel to serve these needs. 

    As the process of right of way acquisition progresses, changes will occur to the proposed pipeline \
route. These may come from land owner requirements, geographic features encountered during survey, \
or constructability issues discovered as more information unfolds about the project.

    JTSI utilizes techniques and strategies developed over a multitude of projects to work with and \
coordinate the efforts of these key resources, right of way agents, surveyors, and legal counsel, \
to harmonize the acquisition of land rights for construction of our clients pipelines and related \
assets. We assist our clients with selecting the best right of way and survey contractors based \
on factors such as knowledge of the local geography, regional political environment, and past \
experience with key land owners. Our focus is to acquire the most cost effective constructable \
route for our clients while maintaining positive relationships with the underlying land owners for \
the long term benefit of our clients.
`,
            }
        },
        4: {
            component: 'card',
            data: {
                title: 'Permit Acquisition',
                body: 
`   Parallel to the acquisition of land rights, permits must be obtained for new pipelines. These permits \
typically fall into two categories, environmental and municipal.  

    Depending on the size and scope of the project, the environmental permit may only require notification or, \
for larger projects, require multi-agency reviews and approvals.  These agencies could include the state \
department of natural resources, fish and wildlife department, state historic preservation office, and \
the US Army Corps of Engineers (USACE).  Usually for more significant permitting processes the USACE acts \
as a coordinating agency to coordinate the reviews, comments, and approvals for all agencies.  

    There are two primary areas of focus for environmental permitting.  The first is often referred to as \
“bugs and bunnies” and addresses the impacts on flora and fauna by the construction of the project. The \
second, “stones and bones”, deals with the archeological impacts of the project.  Due to the highly technical \
nature of the information required for applying for these environmental permits, pipeline owners will contract \
with an experienced environmental service company to assist with development of the application package. \
The environmental service company can obtain specialized information from industry databases, perform field \
surveys to ground truth the data, as well as compile reports to support the findings and demonstrate that \
the pipeline construction will have minimal impacts.

    Many state and local municipalities have a permitting process that must be followed for new pipeline \
construction. This allows these municipalities to ensure that new pipelines are built in accordance \
with regulatory codes, as well as identify the location of the new pipelines with respect to existing \
improvements, such as roads, railroads, and drainage canals, for their operation and maintenance purposes.

    JTSI utilizes our extensive experience to manage and coordinate the efforts of qualified permitting \
contractors to acquire the necessary permits for the construction and operation of pipelines for our \
clients. The activities associated with these efforts include coordination of permitting contractors \
with right of way agents to get access to properties for environmental field surveys.  We also help \
coordinate survey efforts to gather information for the particular needs of permits.  In addition, JTSI \
can provide the technical information needed to address permit specific questions as related to the pipeline \
being installed.  JTSI will continue to pursue the approval of permit applications, bringing to bear all the \
resources of the project team until the permit process is completed.
`,
            }
        },
        5: {
            component: 'card',
            data: {
                title: 'Material Procurement',
                body: 
`   Material procurement is an important step in the process of building a pipeline. While pipeline assets in and \
of themselves typically do not have a wide variety of materials, procuring the correct materials can be \
critical to the success of the project.  The primary material required for construction of a new pipeline \
is the line pipe, which conducts the product through the pipeline and should be designed to endure for \
the life of the asset.

    JTSI can design and specify the appropriate line pipe for our client’s particular application. Line pipe \
can be procured in many different grades from Grade B to X70 and beyond, with each grade having unique \
properties that should be matched with the needs of the system being built. Since steel is typically priced \
by the ton, determining the best ratio of strength to weight per foot can have a major impact on the economics \
of a project.  JTSI specializes in specifying the optimum line pipe to keep the product’s pressure inside the \
pipeline.  

    JTSI can develop project specific line pipe specifications based on API Specification 5L, Line Pipe. Some \
of the key factors which JTSI considers in specifying line pipe include: design and operating conditions, \
product chemical compatibility, as well as installation stresses.  Also, considered are the requirements of \
API Standard 1104, Welding Pipelines and Related Facilities, as the steel used in making the line pipe can \
affect its weldability during construction.  

    Finally, sourcing of the line pipe must be considered. For shorter pipelines, the necessary quantity of \
line pipe can often be found in distributors stock, although sometimes compromises may be required based \
on availability and convenience of stock line pipe.  For longer pipelines, a project specific mill run of \
line pipe is necessary.  The benefit of a mill run is procurement of the exact line pipe needed for the \
project, as well as improved costs over stock line pipe.  The drawback to a mill run can be the schedule \
impact, as the project will be subject to the pipe mill’s (and sometimes steel mill’s) production schedule. \
JTSI helps our clients navigate these challenges to obtain the best pipe solution for the project.

    After line pipe, the pipe coating is probably the next most important material purchased for a pipeline. \
The base pipe coating serves to protect the pipe steel from corrosion in the environment in which the \
pipeline is installed. Additionally, other coatings may be applied to protect the base coating from \
damage, such as directional drill pulls, during installation, add weight to the pipeline to prevent \
buoyancy, or insulate the pipeline for maintaining the product at non-ambient temperatures. JTSI \
can specify and assist our clients with procurement of any of these coatings based on project needs. 

    In addition to line pipe and pipe coating, most pipelines need specialized bends and valves. JTSI \
can specify these components to meet the requirements of API 6D Specification for Valves and bends \
with the appropriate radius of curvature for passage of the internal tools that will be used throughout \
the life of the pipeline.  Finally, JTSI can specify and develop procurement documents for major pieces \
of custom engineered equipment, long lead materials, as well as commodity materials.

    JTSI utilizes its robust procurement process to support our client’s material needs. This process includes \
the following steps:

    1. Develop material and equipment specifications based on design conditions 
    2. Vendor qualification and selection based on capability, experience, and past 
        performance
    3. Develop Request For Quotations packages and submit to a minimum of three 
        qualified bidders
    4. Respond and address bidder’s Requests For Information through the bid 
        process
    5. Receive and evaluation bids, both on a technical and commercial basis 
    6. Recommend a vendor for award of order based on bid review 
    7. Support client’s procurement department in awarding a PO to the selected 
        vendor
    8. Follow up with vendor on any technical issues
    9. Monitor order progress throughout the production process, as well as expedite 
        as needed
    10. Coordinate QA/QC during production including any shop inspections and test 
          witnessing
    11. Coordinate shipping of materials and equipment 
    12. Coordinate material transfer responsibility from vendor to the installation 
          contractor
    13. Capture vendor product documentation for client’s records, especially with 
          regards to meeting regulatory, such as DOT/PHMSA, requirements  
    14. Support client’s procurement and accounting departments with invoicing and 
          PO close out

JTSI has the flexibility to work with client’s proprietary specifications or use our own vast database of specifications for material procurement.
`,
            }
        },
        6: {
            component: 'card',
            data: {
                title: 'Construction Management',
                body: 
`   As the right of way acquisition, permitting, engineering, and material procurement phases \
of a project near completion, plans are typically made to begin the construction process. \
JTSI can help our clients manage this process to get a quality asset built within financial \
and schedule constraints.  

    One of the most important parts of the construction management process which JTSI can perform \
is the definition of the scope of work. The scope of work provides a defined list of work tasks \
that a construction contractor must complete to build the project asset. JTSI utilizes its experience \
in combination with data collected throughout the pre-construction phases of the project to develop \
a project specific scope of work.

    With a scope of work as a start, JTSI can build a bid package for getting bids from contractors. \
JTSI will combine elements including alignment sheets, construction specifications, right of way \
restrictions, permitting requirements, as well as other project information to provide guidance \
for bidders. In addition to technical information, JTSI will add commercial documents using \
client’s terms and conditions.

    Similar to the material procurement process, JTSI follows a standard procedure to help our \
clients select a construction contractor for building their asset. This process includes:

    1. Develop a complete bid package
    2. Construction contractor qualification and selection based on capability, 
        experience, safety record, and past performance
    3. Submit a Request For Quotation to a minimum of three qualified bidders
    4. Coordinate and lead a job showing with prospective bidders.
    5. Respond and address bidder’s Requests For Information through the 
        bid process
    6. Receive and evaluation bids, both on a technical and commercial basis 
    7. Recommend a contractor for award of contractor based on bid review 
    8. Support client’s procurement department in awarding a PO to the selected 
        contractor

    Once a contractor has been selected and a contract is in place, JTSI will coordinate other \
resources to support the construction effort. Typically, surveyors are brought back into the \
project to stake the proposed pipeline alignment and right of way boundaries so that the \
contractor has guidance on where the new pipeline will be laid, as well as prevent the \
contractor from encroaching outside of right of way easements agreed upon with land owners. \
Additionally, inspection services are utilized to monitor the contractor’s work and ensure \
it meets the specifications included in the contract. Other resources that may be coordinated \
include environmental inspectors, non-destructive testing crews, and land agents.  

    With the contractor in place and support resources aligned, JTSI will coordinate a kick off \
meeting to get the construction started.  As the construction activities ramp up, JTSI will \
continue to monitor the work through daily calls, weekly meetings, ad-hoc site visits, etc \
in order to track progress, address technical issues as they arise, and coordinate resources \
to keep the contractor productive.  JTSI will also develop a project specific quality assurance \
and quality control plan in an effort to produce an asset that meets the client’s quality needs.  

    Finally and most importantly, JTSI seeks to promote a safety conscious work environment throughout \
the life of a project. The construction phase has the most risk of safety related incidents. \
As such, JTSI continuously works to cultivate a safety conscious culture for contractors and \
support resources through the use of various tools including: JSA’s, morning tailgate meetings, \
safety moments, safety spotlights, tracking hours worked without incident, etc.  Also, should \
an incident occur, JTSI will ensure that accident reporting plans are in place and followed \
to meet client expectations.

    Other services that JTSI provide associated with the construction phase include:

    1. Progress reporting to client
    2. Coordinate shipping of materials and equipment 
    3. Coordinate material transfer responsibility from vendor to the installation 
        contractor
    4. Capture construction documentation for client’s records, especially with regards 
        to meeting regulatory, such as DOT/PHMSA, requirements  
    5. Support client’s procurement and accounting departments with invoicing and 
        PO close out
`,
            }
        },
        7: {
            component: 'card',
            data: {
                title: 'Testing, Commissioning, and Start Up',
                body:
`   Once the pipeline has been constructed, cleaned, and pressure tested, plans need to be made \
for transitioning the pipeline into service with its intended product. These plans need to \
consider such factors as providing an inert environment for the introduction of product into \
the pipeline, how to measure the product being used to fill the pipeline, and training operations \
personnel on the specifics of operating and maintaining the new asset. JTSI has the experience \
and tools to develop these plans for our clients.  We can coordinate the necessary resources \
to inventory a new pipeline asset.  These typically include third party services, as well as \
operational personnel. JTSI can oversee the complete transition from construction to a functional \
pipeline asset, moving product for our clients to support their business needs.`,
            }
        },
        }
    },
    engineering: {
        body:
        {
        1: {
            component: 'card',
            data: {
                title: 'Pipeline Design and Engineering',
                body: 
`   JTSI specializes in design and engineering for pipelines and related facilities. JTSI utilizes a \
process of Progressive Elaboration to develop and refine designs as more detailed information is generated.

    It is important to note that pipelines in the energy industry are regulated by federal and state laws \
and departments. In general, from a regulatory point of view, pipelines fall into two major categories, \
liquid and gas, based on the compressibility properties of the product being transported. The specific \
federal regulations for each are:

    •  GASES: Code of Federal Regulations Title 49, Part 192 - TRANSPORTATION OF NATURAL AND OTHER GAS BY \
PIPELINE: MINIMUM FEDERAL SAFETY STANDARDS
    •  LIQUIDS: Code of Federal Regulations Title 49, Part 195 - TRANSPORTATION OF HAZARDOUS LIQUIDS BY PIPELINE

The major federal departments that implement and enforce these regulations are:

    •  Pipeline and Hazardous Materials Safety Administration within the Department of Transportation
    •  Federal Energy Regulatory Commission within the Department of Energy

    Each state may have its own departments which enforce these same regulations in addition to any other \
state specific regulations.

    While each regulation references a variety of industry standards, there are some overarching standards \
that are incorporated in these regulations which pipelines must meet with respect to design and \
engineering, including:

    •  ASME/ANSI B31.4, “Pipeline Transportation Systems for Liquid Hydrocarbons and Other Liquids”
    •  ASME/ANSI B31.8, “Gas Transmission and Distribution Piping Systems”

    In addition, to these standards, there may be standards that are specific to the product, which can be \
applied to the design process, such as ASME B31.12, “Hydrogen Piping and Pipelines.”

    With respect to pipelines, the design can be broken down into two categories. The first relates to macro \
design issues. Initially, JTSI conducts routing studies to identify routes for new pipeline assets, \
taking into consideration factors such as existing geography, infrastructure, constructability, landownership, \
power availability, environmental impacts, etc. JTSI seeks to optimize pipeline routes for conservation \
of capital and to minimize impacts. With a route selected, JTSI performs hydraulic modeling studies to \
determine the size and capacity of a new asset. JTSI utilizes information from the pipeline routing analysis, \
as well as receipt and delivery conditions to “right” size the pipe diameter to best meet commercial \
capacity requirements. Depending on the length of the pipeline and other operating constraints, provisions \
may need to be made for pumps or compressors, depending on the product, in order to move the product the \
distance required. JTSI can determine size, location, and power requirements of these assets, should they \
be needed.  

    The second major category relates more to the detailed design of the pipeline system. Typically, the first \
priority of this category is defining the wall thickness and steel grade for the line pipe to be used for \
the construction of the pipeline. JTSI will calculate the appropriate wall thickness to meet regulatory \
requirements based on commercially available steel grades. Multiple options may be developed in order to \
evaluate costs and availability of line pipe. JTSI assists its clients in determining the best option to \
meet project budget and schedule needs.  

    JTSI will perform additional detailed engineering to evaluate wheel loading stresses on the pipeline at \
road and railroad crossings, stresses incurred during horizontal directional drilling operations and \
installations, as well as buoyancy of the pipeline depending on soil conditions. Most of these evaluations \
are based on features encountered by the pipeline during the routing study.  

    With the line pipe specified for both the mainline and special features crossed by the pipeline, the \
type of coating to be used on the pipeline is the next critical material to be determined. JTSI can \
specify the appropriate coating for the pipeline design. The coating performs two significant functions \
for the pipeline. The first and most important is to protect the line pipe steel from external corrosion \
due to the environment in which the pipeline is placed. Corrosion is the second leading cause of pipeline \
leaks, after third party damage. The second is providing a certain level of mechanical protection, \
especially during horizontal directional drill installations. There are other specialized coatings \
that may be used, depending on the service of the pipeline. These may include concrete for buoyancy \
control, internal coating depending on the product, or insulation and/or heat tracing for thermal control.  

Other detailed engineering and designs that JTSI specializes in for pipelines, include:
    •  Launchers and Receivers Sites
    •  Mainline Block Valve Sites
    •  Aboveground to Belowground Transitions
    •  Pipeline anchoring and thrust blocks
    •  Pipeline marking
    •  Cathodic Protection

    For liquids pipelines, regulations require pipeline operators to address the possibility of \
overpressure due to a surge event. JTSI can assist our clients in evaluating surge conditions \
and develop surge mitigations to prevent surge events during operations.
`,
            }
        }
        }
    },
}

export default descriptions;