import { Group, LabelsContainer, Label, Title } from "./styles/Grid.styled"

export default function Grid({data: {title, tabs}}){
    return (
        <>
        <Group><h2>{title}</h2></Group>
        <LabelsContainer>
            {Object.keys(tabs).map(key => {
                return (
                    <Label to={tabs[key].link} activeStyle width=''>
                        <Title>{tabs[key].title}</Title>
                    </Label>
                )
            })
            }
        </LabelsContainer>
        </>
    )
};