import styled from "styled-components";
import { NavLink as Link } from 'react-router-dom';

export const Group = styled.div`
    width: 100%;
    font-size: 24px;
    color: #000;
    text-align: center;
    h2 {
        margin-block-start: 0em;
    }
`

export const LabelsContainer = styled.div`
    border-bottom: 1px solid #cccccc;
    margin-bottom: 40px;
    width: 100%;
    margin: 40px 0; //15%;
    display: grid;
    column-gap: 10px;
    row-gap: 15px;
    grid-template-columns: 31% 31% 31%;
    justify-content: space-evenly;
    align-items: center;
    background-color: #fff;
    padding-bottom: 40px;

    
    @media screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
      width: 90%;
      margin: auto;
    }
    //border-bottom: 1px solid #cccccc;
`

export const Label = styled(Link)`
    &:hover {
        background-color: #003E78;
        box-shadow: 1px 5px 7px 0 #fff;
        //color: #0fd;
    }
    text-decoration: none;
    width:${({width}) => width || '100%'};
    padding: 10% 10%;
    height: 100%;
    background-color: #003Ed8;
    color: #fff;
    display: flex;
    align-items:center;
    justify-content:center;
    border-left: 25px solid #97C1E6;
    border-radius: 0 10px 10px 0px;
    
    @media screen and (max-width: 768px) {
        border-radius: 0 5px 5px 0px;
      margin: auto;
    }
    //box-shadow: 1px 7px 7px 0 #777;
`


export const Title = styled.div`
    text-align: center;
    font-size: 24px;
`