export const Directory = {
	links: {
		1: {
			path: 'ethylene',
			title: 'Purity Ethylene Pipeline System'
		},
		2: {
			path: 'ethane',
			title: 'Purity Ethane Pipeline System'
		},
		3: {
			path: 'ammonia',
			title: 'Ammonia Pipeline System'
		},
		4: {
			path: 'helium',
			title: 'Helium Pipeline'
		},
		5: {
			path: 'lngl',
			title: 'Liquified Natural Gas Loading Pipelines'
		},
	},
	routes: {
		0: {
			route: '',
			'': {
				body: {
				body:
				{
				1: {
					component: 'card',
					data: {
						title: 'Purity Ethylene Pipeline System',
						body: 
`Project Description:  +110 miles of 12-inch ethylene pipeline system including one pump \
station and two meter stations connecting an ethylene cracker to underground storage.

Location:	Texas Gulf Coast Region
Project Value:	$172MM
Product:	Ethylene
Services Provided by JTSI:
	•  Project Management
		◦  Budget and Schedule Management
		◦  Right of way Acquisition
		◦  Pipeline and Site Survey
		◦  Permitting
		◦  Material Procurement
		◦  Equipment Fabrication
		◦  Engineering Design
		◦  Construction Management
		◦  Testing, Commissioning, and Start-up 
	•  Engineering
		◦  Meter Station Design
		◦  Mechanical Design and Drafting
		◦  Civil/Structural Design and Drafting`,
			}
			}
			}
			}
			}
		},
		1: {
			route: 'ethylene',
			ethylene: {
				body: {
				body:
				{
				1: {
					component: 'card',
					data: {
						title: 'Purity Ethylene Pipeline System',
						body: 
`Project Description:  +110 miles of 12-inch ethylene pipeline system including one pump \
station and two meter stations connecting an ethylene cracker to underground storage.

Location:	Texas Gulf Coast Region
Project Value:	$172MM
Product:	Ethylene
Services Provided by JTSI:
	•  Project Management
		◦  Budget and Schedule Management
		◦  Right of way Acquisition
		◦  Pipeline and Site Survey
		◦  Permitting
		◦  Material Procurement
		◦  Equipment Fabrication
		◦  Engineering Design
		◦  Construction Management
		◦  Testing, Commissioning, and Start-up 
	•  Engineering
		◦  Meter Station Design
		◦  Mechanical Design and Drafting
		◦  Civil/Structural Design and Drafting`}
				},
				}
			},}
			},
		2: {
			route: 'ethane',
			ethane: {
				body:{
				body:
				{
				1: {
					component: 'card',
					data: {
						title: 'Purity Ethane Pipeline System',
						body: 
`Project Description:  +130 miles of 12-inch, 16-inch, and 10-inch ethane \
pipeline system including one pump station and two meter stations connecting \
a fractionation plant to an ethylene cracker and underground storage.

Location:	Texas Gulf Coast Region
Project Value:	$179MM
Product:	Ethane
Services Provided by JTSI:
	•  Project Management
		◦  Budget and Schedule Management
		◦  Right of way Acquisition
		◦  Pipeline and Site Survey
		◦  Permitting
		◦  Material Procurement
		◦  Equipment Fabrication
		◦  Engineering Design
		◦  Construction Management
		◦  Testing, Commissioning, and Start-up 
	•  Engineering
		◦  Meter Station Design
		◦  Mechanical Design and Drafting
		◦  Civil/Structural Design and Drafting
`,
					}
				}
				}
			},}
			},
		3: {
			route: 'ammonia',
			ammonia: {
				body: {
				body:
				{
				1: {
					component: 'card',
					data: {
						title: 'Ammonia Pipeline System',
						body: 
`Project Description:  2 miles of 6-inch ammonia pipeline system including two meter \
stations connecting an ammonia terminal to two existing pipeline systems.

Location:	Houston Ship Channel Industrial Complex
Project Value:	$4.4MM
Product:	Ammonia
Services Provided by JTSI:
	•  Project Management
		◦  Budget and Schedule Management
		◦  Right of way Acquisition
		◦  Pipeline and Site Survey
		◦  Permitting
		◦  Material Procurement
		◦  Equipment Fabrication
		◦  Construction Management
		◦  Testing, Commissioning, and Start-up 
	•  Engineering
		◦  Meter Station Design
		◦  Mechanical Design and Drafting
		◦  Civil/Structural Design and Drafting
`,
					}
				}
				}
			},}
			},
		4: {
			route: 'helium',
			helium: {
				body: {
				body:
				{
				1: {
					component: 'card',
					data: {
						title: 'Helium Pipeline',
						body: 
`Project Description:  3 miles of 4-inch helium pipeline system connecting \
an underground helium storage cavern to a helium trucking and process facility.

Location:	Beaumont Texas Area
Project Value:	$1.5MM
Product:	High Pressure Helium
Services Provided by JTSI:
	•  Project Management
		◦  Budget and Schedule Management
		◦  Right of way Acquisition
		◦  Pipeline and Site Survey
		◦  Permitting
		◦  Material Procurement
		◦  Construction Management
		◦  Testing, Commissioning, and Start-up 
`,
					}
				}
				}
			},}
			},
		5: {
			route: 'lngl',
			lngl: {
				body: {
				body:
				{
				1: {
					component: 'card',
					data: {
						title: 'Liquified Natural Gas Loading Pipelines',
						body: 
`Project Description:  FERC permitting design for 1 mile of dual 42-inch insulated \
pipe-in-pipe LNG loading pipelines connecting an LNG export facility to an LNG loading \
dock including two horizontal directional drills.  

Location:	Louisiana Gulf Coast Area
Project Value:	TBD
Product:	Cryogenic Liquified Natural Gas
Services Provided by JTSI:
	•  Engineering
		◦  Pipeline Routing
		◦  Horizontal Directional Drill Design
		◦  Constructability Design
`,
					}
				}
				}
			},}
			},
	},
	content: {

	}
}