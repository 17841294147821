import { SideBar, SideLink, SideContainer } from "./styles/SubNav.styled";
//import { Container } from "./styles/Container.styled";
import Interpreter from './Interpreter.js'

const SideNav = () => {
// take in prop and make bar from list
return (
	<>
		<SideBar>
			<SideLink to="consultingdevelopment" activestyle="true">
				Project Consulting and Development
			</SideLink>
			<SideLink to="projectmanagement" activestyle="true">
				Project Management
			</SideLink>
			<SideLink to="engineering" activestyle="true">
				Design and Engineering
			</SideLink>
		</SideBar>
	</>
)}

export function SubNav({data: links}) {
return (
	<>
		<SideBar>
			{Object.keys(links).map(key => {
				return (
					<SideLink to={links[key].path} activestyle="true">
						{links[key].title}
					</SideLink>
				)
			})}
		</SideBar>
	</>
)}

export default SideNav;