import Header from './../components/Header';
import { Routes, Route } from 'react-router-dom';
import Interpreter from '../components/Interpreter';

import SideNav from '../components/SubNav';
import { Container } from '../components/styles/Container.styled';
import { ContentContainer } from '../components/styles/SubNav.styled';

import Info from '../headers';
import Service from '../service';

import { useLocation } from "react-router-dom"

// USING PATH TO CREATE PAGE FROM FILE
const Temp = () => {

    // GETTING PAGE NAME FROM CURRENT PATH
    let dir = useLocation().pathname;
    dir = "\'" + dir.substring(10,35) + "\'";

    // USING PATHNAME TO GET PAGE CONTENT FROM "service.js" (Service)
    if(Service[eval(dir)] != undefined){
        return (<Interpreter data={Service[eval(dir)] }/>)
    }
    else{
        return(<h2>Error 404: Page Not Found</h2>)
    }

    
}

// GENERATE SERVICES PAGE
function Services() {
    return (
        <>
        <Header data={Info.services}/>
        <Container>
        <SideNav />
        <ContentContainer>
        <Routes>
          <Route path="/" exact element={<Interpreter data={Service.consultingdevelopment} />} />
          <Route path="/projectmanagement" element={<Temp />} />
          <Route path="/consultingdevelopment" element={<Temp />} />
          <Route path="/engineering" element={<Temp />} />
        </Routes>
        </ContentContainer>
        </Container>
        </>
    )
}

export default Services;