import Header from './../components/Header';
import Interpreter from '../components/Interpreter';
import { Container } from '../components/styles/Container.styled';

import Info from '../headers'
import Homecontent from '../homePage'

// GENERATE HOMEPAGE FROM "homePage.js"
function Home() {
    return (
        <>
        <Header data={Info.home}/>
        <Container>
        <Interpreter data={Homecontent} />
        </Container>
        </>
    )
}

export default Home;