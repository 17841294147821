import React, { Component } from 'react';
import Home from './pages/Home';
import Services from './pages/Services';
import Projects from './pages/Projects';
import About from './pages/About';
import Contact from './pages/Contact';

import Footer from './components/Footer';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import GlobalStyles from './components/styles/Global';
import { ThemeProvider } from 'styled-components';
import Navbar from './components/Nav';

const theme = {
  colors: {
    header: '#fff',
    body: '#fff',
    footer: '#003333'
  },
  mobile: '768px',
}

class App extends Component{
state = { data: null};

componentDidMount() {
  this.callBackendAPI()
    .then(res => this.setState({ data: res.express}))
    .catch(err => console.log(err));
}

callBackendAPI = async () => {
  const response = await fetch('/express_backend');
  const body = await response.json();

  if (response.status !== 200) {
    throw Error(body.message)
  }
  console.log(body);
  return body;
};

  render() {
  return (
    <>
      <Router>
      <ThemeProvider theme = {theme}>
      <GlobalStyles />
      <Navbar />
        <Routes>
          <Route path='/' exact element={<Home />} />
          <Route path='/services/*' element={<Services />} />
          <Route path='/projects/*' element={<Projects />} />
          <Route path='/about' element={<About />} />
          <Route path='/contact/*' element={<Contact />} />
        </Routes>
      </ThemeProvider>
      <Footer />
      </Router>
    </>
  );
}
}

export default App;
