import Header from './../components/Header';
import Info from '../headers'
import { Route, Routes } from 'react-router-dom';

import { Container } from '../components/styles/Container.styled';
import { ContentContainer } from '../components/styles/SubNav.styled';
import Card from '../components/Card';

import ContactForm from '../components/Form';

function Completed() {
    const data = {
        title: "Thank You",
        body: 
        `   Thank you for reaching out to us. Your email has been sent. If you have any\
 further questions, feel free to reach out via our email: info@jacintotsi.com`
    }

    return (
        <>
            <Card data={data}/>
        </>
    )
}

function Contact() {
    return (
        <>
        <Header data={Info.contact}/>
        <Container>
                <Routes>
                    <Route path="/" element={<ContactForm />} />
                    <Route path="/completed" element={<Completed />} />
                </Routes>
        </Container>
        </>
    )
}

export default Contact;