import styled from "styled-components";

export const StyledCard = styled.div`
  width:100%;
  margin: 0 0;//15%;
  padding: 40px 20px;
  background-color: #fff;
  color: #000;

  @media screen and (max-width: 768px) {
    padding: 0 0;
    margin: auto;
    width: 90%;
  }
`
  
export const Title = styled.div`
    border-bottom: 1px solid #cccccc;
    line-height: 0.1em;
    margin: 0 0 40px 0;
    margin-bottom: 40px;
    text-align: center;
    font-size: 24px;
    white-space: pre-wrap;
    h2 {
      background: #ffffff none repeat scroll 0 0;
    display: inline-block;
    margin-bottom: -9px;
    padding: 0 10px;
    height: 15px;
    }
    
    @media screen and (max-width: 1080px) {
      font-size: 18px;
      line-height: 1.5em;
      margin-bottom: 60px;
      h2 {
        //font-size: 24px;
        display: block;
        height: 30px;
        //width: 100%;
        white-space: pre-wrap;
      
      }
    }
    
    @media screen and (max-width: 768px) {
      font-size: 18px;
      line-height: 1.5em;
      h2 {
        //font-size: 24px;
        display: block;
        height: 30px;
        //width: 100%;
        white-space: pre-wrap;
      
      }
    }
    //margin-bottom: 15px;
`

export const Body = styled.div`
  white-space: pre-wrap;
  text-align: justify;
  text-justify: inter-word;
  font-size: 24px;
  padding-bottom: 40px;
  border-bottom: 1px solid #cccccc;
  @media screen and (max-width: 768px) {
      font-size: 18px;
      white-space: wrap;
    }
`