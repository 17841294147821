import { StyledCard, Title, Body } from "./styles/Card.styled"

export default function Card({data: {title, body}}) {
    return (
        <StyledCard >
            <Title>
                <h2>
                {title}
                </h2>
            </Title>
            <Body>
                {body}
            </Body>
        </StyledCard>
    )
}

/*
<div>
<ReactPlayer
    url={audioFile}
    width="100%"
    height="50px"
    playing={false}
    controls={true}
/>
</div>
*/

//<div>
//
//</div>

// 