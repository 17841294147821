import styled from "styled-components";

export const StyledHeader = styled.header`
    background-color: ${({ theme }) => theme.colors.header}; // THEME WRAPPER IN App.js FILE
    //padding: 40px 0px;
`

export const Background = styled.div`
    padding: 40px 0px;
    width: 100%;
    height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-position-y: center;
    background-repeat: repeat-x;
    //backdrop-filter: blur(8px);
    //-webkit-backdrop-filter: blur(8px);
    background-size: cover;
    background-color: #777;
    background-image: url(images/${({image}) => image || ''});
`

export const Nav = styled.nav`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
    @media (max-width: ${({ theme }) => theme.mobile}) {
        flex-direction: column;
    }
`

export const Image = styled.img`
    height: 100%;
    margin-left: 40px;
    display: inline;
    z-index: -1;
    @media (max-width: ${({ theme }) => theme.mobile}) {
        margin: 40px 0 30px;
    }
`

export const Title = styled.div`
    text-align: center;
    font-size: 24px;
    padding-bottom: 20px;
`

export const Body = styled.div`
    text-align: center;
    font-size: 18px;
`

export const Card = styled.div`
    border-radius: 15px;
    background-color: #003Ed8;
    box-shadow: 1px 10px 10px 0 #013;
    color: #fff;
    width: 40%;
    padding: 50px 20px;
    //box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    //z-index: 1;
    background: rgba(0, 62, 216, 0.90);
    backdrop-filter: blur(3px);
    
    @media screen and (max-width: 768px) {
        margin:auto;
        width: 90%;
    }
`